import React, { useContext, useEffect, useState } from "react";
import { ApiContext, createAPIClients } from "./clients";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import MUILink from "@material-ui/core/Link";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { LoginViewModel, AuthViewModel } from "../gensrc/client";
import {
  MUIFormControl,
  muiFieldRenderer,
  muiCheckProps,
  muiCheckRenderer,
  muiFieldProps,
} from "@react-typed-form/mui";
import { Redirect, useLocation, Link, useHistory } from "react-router-dom";
import { LocationDescriptor } from "history";
import { SignUpPath } from "./signup";
import { useGlobalApi } from "./global";
import { BaseUrl } from "./config";
import { ResetPasswordPath } from "./password";
import { useLoginStyles } from "./styles";
import { ProgressButton } from "./utils";
import {
  ControlDef,
  ControlDefs,
  useFormGroup,
  FieldRenderer,
  mkFieldRenderer,
} from "@react-typed-form/core";

const loginForm: ControlDefs<LoginViewModel, MUIFormControl> = {
  email: { label: "Email", required: true },
  password: {
    label: "Password",
    autoComplete: "current-password",
    required: true,
    type: "password",
  },
  rememberMe: { label: "Remember me" },
};

export const LoginPath = "/login";

export function LoginPage() {
  const apiClients = useContext(ApiContext);
  const classes = useLoginStyles();
  const location = useLocation<LocationDescriptor>();
  const history = useHistory();
  const globalApi = useGlobalApi();
  const query = new URLSearchParams(location.search);
  const resetting = query.has("reset");
  const hasReset = query.has("hasReset");
  const [resetInProgress, setResetInProgress] = useState(false);
  const { FormField, state, useFieldRenderer } = useFormGroup(
    loginForm,
    {
      email: "",
      password: "",
      rememberMe: false,
    },
    muiFieldRenderer as FieldRenderer<
      MUIFormControl,
      string,
      string | undefined,
      TextFieldProps
    >
  );
  const CheckField = useFieldRenderer(muiCheckRenderer);
  globalApi.useHideNav();

  function login(loginForm: LoginViewModel) {
    apiClients.authClient
      .authenticate(loginForm)
      .then((lr) => globalApi.loggedIn(lr, loginForm.rememberMe));
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  if (apiClients.authenticated) {
    const to = location.state || "/";
    return <Redirect to={to} />;
  }

  function resetPassword(m: LoginViewModel) {
    setResetInProgress(true);
    apiClients.authClient
      .forgotPassword({
        email: m.email,
        restPasswordUrl: BaseUrl + ResetPasswordPath,
      })
      .then(() => history.replace({ ...location, search: "hasReset" }));
  }

  function resetForm() {
    if (hasReset) {
      return (
        <>
          <Typography component="h1" variant="h5">
            Reset confirmation sent
          </Typography>
          <Typography align="center">
            Please follow the instructions in your email to finish resetting
            your password.
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <div className={classes.form}>
          <FormField field="email" fullWidth margin="normal" />
          <ProgressButton loading={resetInProgress}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={resetInProgress}
              className={classes.submit}
              onClick={() => resetPassword(state.current.value)}
            >
              Reset
            </Button>
          </ProgressButton>
        </div>
      </>
    );
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        {resetting || hasReset ? (
          resetForm()
        ) : (
          <>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <FormField field="email" fullWidth margin="normal" />
              <FormField field="password" fullWidth />
              <CheckField field="rememberMe" color="primary" />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => {
                  e.preventDefault();
                  login(state.current.value);
                }}
              >
                Sign In
              </Button>
            </form>
            <Grid container>
              <Grid item>
                <MUILink
                  variant="body2"
                  to={{ pathname: SignUpPath, state: location.state }}
                  component={Link}
                >
                  Don't have an account? Sign Up
                </MUILink>
              </Grid>
              <Grid item>
                <MUILink variant="body2" to="?reset" component={Link}>
                  Forgot your password? Reset here
                </MUILink>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </Container>
  );
}
