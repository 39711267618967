import { AuthViewModel, ApiException } from "../gensrc/client";
import { ReactElement, createContext, useContext } from "react";
import { Location } from "history";

export const GlobalApiContext = createContext<GlobalApi | undefined>(undefined);

export interface GlobalApi {
  loggedIn(l: AuthViewModel, rememberMe: boolean): void;
  addSnack(r: ReactElement): void;
  backOrHome(location: Location): void;
  useHideNav(): void;
  onCustomerId(id: string): void;
}

export function useGlobalApi(): GlobalApi {
  const api = useContext(GlobalApiContext);
  if (!api) {
    throw new Error("Must provide the GlobalAPI");
  }
  return api;
}
