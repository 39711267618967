import { createMuiTheme } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import { ApiUrl } from "./config";

const winstonTheme = createMuiTheme({
  palette: {
    primary: { main: "#0e103f", contrastText: "#f7cc25" },
    secondary: { main: "#3a50f7" },
    type: "dark",
    // background: {
    //   default: "#0e103f",
    //   paper: "#fff",
    // },
  },
  mixins: {
    toolbar: {
      height: 100,
    },
  },
});

export const winstonTheme2 = createMuiTheme({
  palette: {
    primary: { main: "#0e103f", contrastText: "#f7cc25" },
    secondary: { main: "#f7cc25" },
    background: {
      default: "#0e103f",
    },
    type: "dark",
  },
  mixins: {
    toolbar: {
      height: 100,
    },
  },
});

const winstonConfig = {
  theme: winstonTheme,
  logo: require("../public/cellars-logo.png"),
  products: true,
  subscriptions: false,
  specials: false,
  canShip: false,
  latitude: -42.871996,
  longitude: 147.314648,
  title: "Winston Cellars",
  address1: "114 Federal Street",
  address3: "North Hobart",
  postcode: 7000,
  storeTheme: winstonTheme2,
};

export const bopTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#dd0b14",
    },
    secondary: indigo,
    type: "light",
  },
  mixins: {
    toolbar: {
      height: 100,
    },
  },
});

const bopConfig: typeof winstonConfig = {
  theme: bopTheme,
  logo: ApiUrl + "/BeerCollective/site/_assets/img/jquery-logo.png",
  products: true,
  subscriptions: true,
  specials: true,
  canShip: true,
  latitude: -42.87,
  longitude: 147.312,
  title: "Brands On Parade",
  address1: "1 Street",
  address3: "Hobart",
  postcode: 7000,
  storeTheme: bopTheme,
};

export const storeFrontConfig = true ? winstonConfig : bopConfig;
