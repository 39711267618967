import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  ReactElement,
  Ref,
} from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from "@material-ui/icons/Person";
import ShopIcon from "@material-ui/icons/Shop";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import { createAPIClients, ApiContext, UserAuth } from "./clients";
import {
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
  RouteProps,
} from "react-router-dom";
import { LoginPage, LoginPath } from "./login";
import {
  Badge,
  Button,
  Menu,
  MenuItem,
  BottomNavigation,
  BottomNavigationAction,
  Hidden,
  Typography,
  Drawer,
  Snackbar,
  Backdrop,
  Box,
  Paper,
} from "@material-ui/core";
import { AuthViewModel } from "../gensrc/client";
import { Cart } from "./cart";
import { UpdateDetailsPath, UpdateDetails } from "./updatedetails";
import { ViewOrdersPath, ViewOrders } from "./vieworders";
import { MainTabs } from "./maintabs";
import { ViewBox } from "./viewbox";
import { ViewCart, ViewCartPath } from "./viewcart";
import { storeFrontConfig } from "./storeconfig";
import { SignUpPage, SignUpPath } from "./signup";
import { ConfirmEmailPath, ConfirmEmail } from "./confirm";
import { GlobalApiContext, GlobalApi } from "./global";
import {
  ChangePasswordPath,
  ChangePassword,
  ResetPasswordPath,
  ForgotPassword,
} from "./password";
import { AgeGate } from "./agegate";
import clsx from "clsx";

const bgLogo = require("../public/winback.jpg");

function navLinks(cart: number) {
  return [
    {
      label: "Shop",
      value: "/shop",
      order: 1,
      icon: <ShopIcon />,
    },
    {
      label: "Cart",
      value: ViewCartPath,
      order: 3,
      icon: (
        <Badge badgeContent={cart} color="secondary">
          <ShoppingCart />
        </Badge>
      ),
    },
    { label: "Orders", order: 2, value: ViewOrdersPath, icon: <ReceiptIcon /> },
  ];
}

export function WaitAuth({ children, ...rest }: RouteProps) {
  const apiClient = useContext(ApiContext);
  return (
    <Route
      {...rest}
      render={() => (apiClient.authChecked ? children : <div />)}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 240,
      maxHeight: 250,
    },
    maxWidth: "75%",
    maxHeight: 100,
    marginLeft: theme.spacing(3.75),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  topbar: {
    display: "flex",
    backgroundImage: `url(${bgLogo})`,
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    justifyContent: "space-between",
  },
  topImage: {
    flexGrow: 1,
    maxWidth: 1242,
  },
  topright: {
    flexGrow: 0,
  },
  userIcon: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  bottomNavSpace: {
    marginBottom: 56,
  },
  bottomNav: {
    position: "fixed",
    bottom: 0,
    width: "100vw",
  },
  bottonNavSelected: {
    "&.Mui-selected": {
      color: theme.palette.primary.contrastText,
    },
  },
  navIcon: {
    marginRight: theme.spacing(7),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  linksPaper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(5, 5),
      padding: theme.spacing(3),
      border: `7px solid ${theme.palette.primary.contrastText}`,
    },
    margin: theme.spacing(1, 1),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [apiClients, setApiClients] = useState(createAPIClients(null, false));
  const currentUser = apiClients.authenticated;
  const [cart, setCart] = useState(Cart.itemCount$.value);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const location = useLocation();
  const [snackOpen, setSnackOpen] = useState(false);
  const [currentSnack, setCurrentSnack] = useState<ReactElement>();
  const [hideNav, setHideNav] = useState(false);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function doNav(path: string) {
    window.scrollTo({ top: 0 });
    history.push(path);
  }

  useEffect(() => {
    Cart.itemCount$.subscribe(setCart);
    const auth = sessionStorage.getItem("auth") ?? localStorage.getItem("auth");
    if (auth) {
      setApiClients(createAPIClients(JSON.parse(auth), true));
    } else {
      setApiClients(createAPIClients(null, true));
    }
  }, []);

  function updateAuth(auth: UserAuth) {
    setApiClients(createAPIClients(auth, true));
    const authJSON = JSON.stringify(auth);
    if (auth.rememberMe) {
      localStorage.setItem("auth", authJSON);
    }
    sessionStorage.setItem("auth", authJSON);
  }

  function loggedIn(loginResponse: AuthViewModel, rememberMe: boolean) {
    const auth: UserAuth = {
      token: loginResponse.token!,
      customerId: loginResponse.customerId,
      rememberMe,
    };
    updateAuth(auth);
  }

  function logout() {
    setAnchorEl(null);
    sessionStorage.removeItem("auth");
    localStorage.removeItem("auth");
    Cart.itemCount$.next(0);
    return setApiClients(createAPIClients(null, true));
  }

  function gotoLink(path: string) {
    setAnchorEl(null);
    history.push(path, location);
  }

  function renderUser() {
    return (
      <div className={classes.userIcon}>
        <IconButton onClick={openMenu} color="inherit">
          <PersonIcon />
        </IconButton>
        <Hidden smDown>
          <Typography>Account</Typography>
        </Hidden>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => gotoLink(UpdateDetailsPath)}>
            My details
          </MenuItem>
          <MenuItem onClick={() => gotoLink(ViewOrdersPath)}>
            My orders
          </MenuItem>
          <MenuItem onClick={() => gotoLink(ChangePasswordPath)}>
            Change password
          </MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </div>
    );
  }

  const globalApi = useMemo(
    () =>
      ({
        loggedIn,
        addSnack: (s) => {
          setCurrentSnack(s);
          setSnackOpen(true);
        },
        backOrHome(l) {
          history.replace(l.state ? l.state : "/shop");
        },
        useHideNav() {
          useEffect(() => {
            setHideNav(true);
            return () => setHideNav(false);
          }, []);
        },
        onCustomerId(customerId: string) {
          if (currentUser) {
            updateAuth({ ...currentUser, customerId });
          }
        },
      } as GlobalApi),
    []
  );

  const footer = useMemo(
    () => (
      <div className={classes.warning}>
        <Typography variant="h5">WARNING</Typography>
        <Typography>
          Under the Liquor Licensing Act 1990 it is an offence:
        </Typography>
        <Typography>
          for liquor to be delivered to a person under the age of 18 years.
        </Typography>
        <Typography variant="caption">
          Penalty: Fine not exceeding 20 penalty units
        </Typography>
        <Typography>
          for a person under the age of 18 years to purchase liquor.
        </Typography>
        <Typography variant="caption">
          Penalty: Fine not exceeding 10 penalty unit
        </Typography>
      </div>
    ),
    [classes]
  );

  const routeSwitch = useMemo(
    () => (
      <Switch>
        {routes.map((r) => {
          var children = (
            <>
              <r.page />
              {footer}
            </>
          );

          if (r.theme) {
            children = (
              <ThemeProvider theme={r.theme}>
                <div
                  style={{
                    backgroundColor: r.theme.palette.background.default,
                    flexGrow: 1,
                  }}
                >
                  {children}
                </div>
              </ThemeProvider>
            );
          }
          const R = r.auth ? WaitAuth : Route;
          return (
            <R key={r.path} path={r.path} exact={r.exact} children={children} />
          );
        })}
      </Switch>
    ),
    [footer]
  );

  return (
    <ApiContext.Provider value={apiClients}>
      <GlobalApiContext.Provider value={globalApi}>
        <CssBaseline />
        <div className={classes.root}>
          <AgeGate />
          <div className={classes.topbar}>
            <div className={classes.topImage}>
              <Link to="/">
                <img src={storeFrontConfig.logo} className={classes.logo} />
              </Link>
            </div>
            <div className={classes.topright}>
              <Paper className={classes.linksPaper} variant="outlined">
                <Hidden xsDown>
                  {[...navLinks(cart)]
                    .sort((a, b) => a.order - b.order)
                    .map((l) => (
                      <div key={l.order} className={classes.navIcon}>
                        <IconButton
                          color="inherit"
                          onClick={() => doNav(l.value)}
                        >
                          {l.icon}
                        </IconButton>
                        <Typography>{l.label}</Typography>
                      </div>
                    ))}
                </Hidden>
                {currentUser ? (
                  renderUser()
                ) : (
                  <Button
                    to="/login"
                    variant="outlined"
                    color="inherit"
                    component={Link}
                  >
                    Login
                  </Button>
                )}
              </Paper>
            </div>
          </div>
          <main className={classes.content}>
            {routeSwitch}
            {hideNav ? (
              <Hidden xsDown>
                <div className={classes.bottomNavSpace} />
              </Hidden>
            ) : (
              <Hidden smUp>
                <BottomNavigation
                  showLabels
                  className={classes.bottomNav}
                  value={location.pathname}
                  onChange={(_, c) => doNav(c)}
                >
                  {navLinks(cart).map((nl) => (
                    <BottomNavigationAction
                      classes={{ root: classes.bottonNavSelected }}
                      label={nl.label}
                      key={nl.value}
                      value={nl.value}
                      icon={nl.icon}
                    />
                  ))}
                </BottomNavigation>
              </Hidden>
            )}
          </main>
          <Snackbar
            open={snackOpen}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setSnackOpen(false)}
          >
            {currentSnack}
          </Snackbar>
        </div>
      </GlobalApiContext.Provider>
    </ApiContext.Provider>
  );
}

const routes = [
  { path: LoginPath, page: LoginPage },
  {
    exact: true,
    path: "/",
    page: MainTabs,
    theme: storeFrontConfig.storeTheme,
  },
  { path: "/pack/:packId/box/:boxId", page: ViewBox, auth: true },
  { path: ViewCartPath, page: ViewCart, auth: true },
  { path: ResetPasswordPath, page: ForgotPassword, auth: true },
  {
    path: ViewOrdersPath,
    page: ViewOrders,
    auth: true,
    theme: storeFrontConfig.storeTheme,
  },
  { path: ChangePasswordPath, page: ChangePassword, auth: true },
  { path: UpdateDetailsPath, page: UpdateDetails, auth: true },
  { path: ConfirmEmailPath, page: ConfirmEmail },
  { path: SignUpPath, page: SignUpPage },
  { path: "/:tabId", page: MainTabs, theme: storeFrontConfig.storeTheme },
];
