import {
  AdminClient,
  AuthClient,
  ClubPackClient,
  ProductClient,
  CartClient,
  OrdersClient,
  AuthViewModel,
} from "../gensrc/client";
import { createContext, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { LoginPath } from "./login";
import { ApiUrl } from "./config";

export type UserAuth = {
  customerId?: string | null;
  token: string;
  rememberMe: boolean;
};

export type APIClients = {
  authClient: AuthClient;
  cartClient: CartClient;
  clubPackClient: ClubPackClient;
  productClient: ProductClient;
  ordersClient: OrdersClient;
  authChecked: boolean;
  authenticated: UserAuth | null;
};

export function createAPIClients(
  userAuth: UserAuth | null,
  authChecked: boolean
): APIClients {
  const http = {
    fetch: (url: RequestInfo, init?: RequestInit) => {
      const newInit = init || {};
      const token = userAuth?.token;
      const headers = new Headers(newInit.headers);
      if (token) {
        headers.append("Authorization", "Bearer " + token);
        newInit.headers = headers;
      }
      return window.fetch(url, newInit);
    },
  };
  const baseUrl = ApiUrl;
  return {
    authChecked,
    authClient: new AuthClient(baseUrl, http),
    clubPackClient: new ClubPackClient(baseUrl, http),
    productClient: new ProductClient(baseUrl, http),
    cartClient: new CartClient(baseUrl, http),
    ordersClient: new OrdersClient(baseUrl, http),
    authenticated: userAuth,
  };
}

export const ApiContext = createContext(createAPIClients(null, false));

export function useAuthenticatedUser(): [APIClients, UserAuth] {
  const apiClients = useContext(ApiContext);
  const location = useLocation();
  const history = useHistory();
  if (apiClients.authenticated) {
    return [apiClients, apiClients.authenticated];
  }
  history.replace(LoginPath, location);
  return [apiClients, { token: "", customerId: "", rememberMe: false }];
}
