import React, { useState, useContext } from "react";
import { MUIFormControl, muiFieldProps } from "@react-typed-form/mui";
import {
  ControlDefs,
  useFormGroup,
  mkFieldRenderer,
} from "@react-typed-form/core";
import {
  Container,
  Avatar,
  Button,
  Typography,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useLoginStyles } from "./styles";
import { ApiContext, useAuthenticatedUser } from "./clients";
import {
  ChangePasswordViewModel,
  ResetPasswordForJolse,
} from "../gensrc/client";
import { badRequestToErrors, ProgressButton } from "./utils";
import { useGlobalApi } from "./global";
import Alert from "@material-ui/lab/Alert";
import { useLocation } from "react-router-dom";

const passwordField = {
  label: "New Password",
  autoComplete: "new-password",
  required: true,
  type: "password",
};

const confirmPassword = {
  label: "Confirm Password",
  autoComplete: "new-password",
  required: true,
  type: "password",
};

const ChangeForm: ControlDefs<ChangePasswordViewModel, MUIFormControl> = {
  oldPassword: {
    label: "Old Password",
    autoComplete: "current-password",
    required: true,
    type: "password",
  },
  newPassword: passwordField,
  confirmPassword,
};

const ResetForm: ControlDefs<ResetPasswordForJolse, MUIFormControl> = {
  password: passwordField,
  confirmPassword,
};

export const ResetPasswordPath = "/password/reset";

export const OurField = (p: TextFieldProps) => (
  <TextField {...p} fullWidth margin="normal" />
);

export function ForgotPassword() {
  const classes = useLoginStyles();
  const apiClient = useContext(ApiContext);
  const location = useLocation();
  const [resetting, setResetting] = useState(false);
  const params = new URLSearchParams(location.search);
  const globalApi = useGlobalApi();
  const { state, FormField, updateState } = useFormGroup(
    ResetForm,
    { password: "" },
    mkFieldRenderer(muiFieldProps, OurField)
  );

  function finishReset() {
    const v = state.current.value;
    setResetting(true);
    apiClient.authClient
      .resetPassword({
        ...v,
        userId: params.get("userId")!,
        code: params.get("code")!,
      })
      .then((av) => {
        globalApi.loggedIn(av, false);
        globalApi.addSnack(
          <Alert severity="success">Password was reset</Alert>
        );
        globalApi.backOrHome(location);
      })
      .catch((e) => {
        const errors = badRequestToErrors<ResetPasswordForJolse>(e);
        updateState({ dirty: true, touched: true }, errors);
        setResetting(false);
      });
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5">Reset Password</Typography>
        <div className={classes.form}>
          <FormField field="password" />
          <FormField field="confirmPassword" />
          <ProgressButton loading={resetting}>
            <Button
              className={classes.submit}
              fullWidth
              variant="contained"
              color="primary"
              disabled={resetting}
              onClick={finishReset}
            >
              Reset password
            </Button>
          </ProgressButton>
        </div>
      </div>
    </Container>
  );
}

export const ChangePasswordPath = "/password/change";

export function ChangePassword() {
  const classes = useLoginStyles();
  const [apiClient] = useAuthenticatedUser();

  const globalApi = useGlobalApi();
  const location = useLocation();
  const { state, FormField } = useFormGroup(
    ChangeForm,
    {
      oldPassword: "",
      newPassword: "",
    },
    mkFieldRenderer(muiFieldProps, OurField)
  );

  function changePassword() {
    const formValues = state.current.value;
    // setInitialForm(formValues);
    apiClient.authClient
      .changePassword(formValues)
      .then(() => {
        globalApi.addSnack(<Alert severity="success">Changed password</Alert>);
        globalApi.backOrHome(location);
      })
      .catch(
        () => {}
        // setInitialState({
        //   dirty: true,
        //   touched: true,
        //   error: badRequestToErrors<ChangePasswordViewModel>(e),
        // })
      );
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5">Change Password</Typography>
        <div className={classes.form}>
          <FormField field="oldPassword" />
          <FormField field="newPassword" />
          <FormField field="confirmPassword" />
          <Button
            className={classes.submit}
            fullWidth
            variant="contained"
            color="primary"
            onClick={changePassword}
          >
            Change password
          </Button>
        </div>
      </div>
    </Container>
  );
}
