import { render } from "react-dom";
import Dashboard from "./dashboard";
import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { indigo } from "@material-ui/core/colors";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter } from "react-router-dom";
import { storeFrontConfig } from "./storeconfig";
import "googlemaps";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_s6kImbwpk4E3i46eie9sXOLJ");

window.document.title = storeFrontConfig.title;

render(
  <Elements stripe={stripePromise}>
    <MuiThemeProvider theme={storeFrontConfig.theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <BrowserRouter>
          <Dashboard />
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Elements>,
  document.getElementById("app")
);
