import { Backdrop, Typography, Button, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  ageGate: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ageButtons: {
    display: "flex",
    margin: theme.spacing(2),
  },
  ageButton: {
    marginLeft: theme.spacing(1),
  },
}));

export function AgeGate() {
  const classes = useStyles();
  const [showGate, setShowGate] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const confirmed = localStorage.getItem("ageConfirm");
    setShowGate(confirmed !== "yes");
  }, []);
  function confirm() {
    setShowGate(false);
    localStorage.setItem("ageConfirm", "yes");
  }
  return (
    <Backdrop open={showGate} className={classes.backdrop}>
      <div className={classes.ageGate}>
        <Typography variant="h2">OVER 18?</Typography>
        <img src={require("../public/winston photo b&w.png")} />
        <div className={classes.ageButtons}>
          <Button
            variant="contained"
            color="primary"
            className={classes.ageButton}
            onClick={confirm}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.ageButton}
            onClick={() =>
              document.location.assign("https://drinkwise.org.au/")
            }
          >
            No
          </Button>
        </div>
      </div>
    </Backdrop>
  );
}
