import { useContext, useState, useEffect } from "react";
import { ApiContext } from "./clients";
import {
  Container,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
  makeStyles,
  Grid,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItem,
  List,
  CardActions,
  Button,
  CardHeader,
  Box,
} from "@material-ui/core";
import React from "react";
import { ClubPack, ClubPackLine, ClubPackBox } from "../gensrc/client";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { blue, grey } from "@material-ui/core/colors";
import { imageLink } from "./utils";
import { useSubscribeToPack } from "./packs";
import { FullProgress } from "./fullprogress";

const useStyles = makeStyles((t) => ({
  boxImage: { height: 240 },
  topContainer: { margin: t.spacing(2) },
  avatar: { backgroundColor: t.palette.primary.main },
  header: { backgroundColor: grey[200] },
}));

export function ViewBox() {
  const { boxId, packId } = useParams<{ packId: string; boxId: string }>();
  const history = useHistory();
  const apiClient = useContext(ApiContext);
  const [clubPack, setClubPack] = useState<ClubPackBox>();
  const classes = useStyles();
  useEffect(() => {
    apiClient.clubPackClient.getBox(boxId).then(setClubPack);
  }, []);
  const subscribe = useSubscribeToPack();

  function renderBox(b: ClubPackLine) {
    const product = b.product!;
    return (
      <Grid item xs={12} md={4} key={b.id}>
        <Card>
          <CardHeader
            className={classes.header}
            avatar={
              <Avatar className={classes.avatar} children={b.quantity + "x"} />
            }
            title={<Typography variant="h6">{product.name}</Typography>}
          />
          {product && (
            <CardMedia
              className={classes.boxImage}
              image={imageLink(product.imageId!)}
              title={product.name}
            />
          )}
          <CardContent>
            <div
              dangerouslySetInnerHTML={{
                __html: product.description || "",
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Container maxWidth="lg">
      <div className={classes.topContainer}>
        {!clubPack ? (
          <FullProgress />
        ) : (
          <>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography variant="h3" color="textPrimary">
                {clubPack.name}
              </Typography>
              <Box m={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => subscribe(packId)}
                >
                  ${Number(100).toFixed(2)} Subscribe
                </Button>
              </Box>
            </Grid>
            <Grid container spacing={4}>
              {clubPack.items!.map(renderBox)}
            </Grid>
          </>
        )}
      </div>
    </Container>
  );
}
