import React, { ChangeEvent, useContext } from "react";
import { Tabs, Tab, makeStyles, MuiThemeProvider } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { ShowPacks } from "./packs";
import { Products } from "./products";
import { ApiContext } from "./clients";
import { storeFrontConfig } from "./storeconfig";

const tabs = [
  {
    name: "Shop",
    value: "shop",
    page: <Products />,
    enabled: storeFrontConfig.products,
  },
  {
    name: "Subscription",
    value: "packs",
    page: <ShowPacks />,
    enabled: storeFrontConfig.subscriptions,
  },
  {
    name: "Specials",
    value: "specials",
    page: <Products categories={["Specials"]} />,
    enabled: storeFrontConfig.specials,
  },
].filter((t) => t.enabled);

const useStyles = makeStyles((t) => ({
  tabs: { backgroundColor: t.palette.primary.main, color: t.palette.grey[50] },
}));

export function TabsOnly(props: { tabId?: string }) {
  const classes = useStyles();
  const history = useHistory();
  function changeTab(event: ChangeEvent<{}>, tab: string) {
    history.replace("/" + tab);
  }
  return (
    <Tabs
      className={classes.tabs}
      value={props.tabId}
      onChange={changeTab}
      variant="fullWidth"
      textColor="inherit"
    >
      <Tab value="shop" label="Shop" />
      <Tab value="packs" label="Subscriptions" />
      <Tab value="specials" label="Specials" />
    </Tabs>
  );
}

export function MainTabs() {
  var { tabId } = useParams<{ tabId: string }>();
  if (!tabId) {
    tabId = "shop";
  }
  const currentTab = tabs.find((t) => t.value === tabId);
  const clients = useContext(ApiContext);
  return (
    <>
      {tabs.length > 1 && <TabsOnly tabId={tabId} />}
      {clients.authChecked && currentTab && currentTab.page}
    </>
  );
}
