import {
  ControlDefs,
  useFormGroup,
  mkFieldRenderer,
  FormGroupState,
} from "@react-typed-form/core";
import React from "react";
import {
  MUIFormControl,
  muiFieldProps,
  muiDateProps,
  muiKeyboardDateField,
} from "@react-typed-form/mui";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";
import { TextField, TextFieldProps, Grid, Typography } from "@material-ui/core";
import { Customer } from "../gensrc/client";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

export type CustomerForm = {
  firstName: string;
  lastName: string;
  mobilePhone: string;
  dateOfBirth: Date | null;
  address1: string;
  address2: string;
  state: string;
  postcode: string;
};

export function formToCustomer(customerForm: CustomerForm): Customer {
  const {
    firstName,
    lastName,
    mobilePhone,
    address1,
    address2,
    state,
    postcode,
  } = customerForm;
  return {
    firstName,
    lastName,
    mobilePhone,
    postalAddress: {
      addressId: undefined!,
      addressLine1: address1,
      addressLine3: address2,
      postcode: Number(postcode),
      state: state,
    },
    customerID: undefined!,
    applyWet: false,
    customPriceLevel: false,
    billingSameAsPostal: true,
    totalReferralCount: 0,
    currentReferralCreditCount: 0,
    signUpDate: new Date().toISOString(),
    dateOfBirth: customerForm.dateOfBirth?.toISOString(),
  };
}

export function formFromCustomer(customer: Customer): CustomerForm {
  const address = customer.postalAddress;
  return {
    firstName: customer.firstName || "",
    mobilePhone: customer.mobilePhone || "",
    dateOfBirth: customer.dateOfBirth ? new Date(customer.dateOfBirth) : null,
    lastName: customer.lastName,
    address1: address?.addressLine1 || "",
    address2: address?.addressLine3 || "",
    state: address?.state || "",
    postcode: address?.postcode.toString() || "",
  };
}

// "firstName": formData.firstName,
// 		   "lastName": formData.lastName,
// 		   "emailAddress": formData.email,
// 		   "mobilePhone": formData.phone,
// 		   "dateOfBirth": formData.dateOfBirth,
// 		   "postalAddress": {
// 			 "addressLine1": formData.add1,
// 			 "addressLine3": formData.add2,
// 			 "state": formData.state,
// 			 "country": "Australia",
// 			 "postcode": formData.postcode
// 		   },
// 		   "applyWet": false,
// 		   "customPriceLevel": false,
// 		   "billingSameAsPostal": true,
//            "customerID": sessionStorage.getItem('customerId')

const notEmpty = (s: string) =>
  !s || !s.trim() ? "Please fill this in" : undefined;

const customerFormDef: ControlDefs<CustomerForm, MUIFormControl> = {
  firstName: {
    label: "First Name",
    required: true,
    autoComplete: "first name",
    validator: notEmpty,
  },
  lastName: { label: "Last Name", required: true, validator: notEmpty },
  mobilePhone: { label: "Mobile Phone", autoComplete: "tel" },
  dateOfBirth: { label: "Date of Birth", autoComplete: "bday" },
  address1: { label: "Adress Line 1", autoComplete: "shipping address-line1" },
  address2: { label: "Adress Line 2", autoComplete: "shipping address-line2" },
  state: { label: "State", autoComplete: "shipping region" },
  postcode: { label: "Postcode", autoComplete: "shipping postal-code" },
};

export const emptyCustomerForm: CustomerForm = {
  firstName: "",
  lastName: "",
  mobilePhone: "",
  dateOfBirth: null,
  address1: "",
  address2: "",
  postcode: "",
  state: "",
};

function MyTextField(p: TextFieldProps) {
  return <TextField {...p} variant="outlined" fullWidth />;
}

export function CustomerPage(props: {
  initialValue: CustomerForm;
  onStateChange: (state: FormGroupState<CustomerForm>) => void;
}) {
  const { FormField, useFieldRenderer } = useFormGroup(
    customerFormDef,
    props.initialValue,
    mkFieldRenderer(muiFieldProps, (p) => <TextField {...p} fullWidth />),
    props.onStateChange
  );
  const DateField = useFieldRenderer(muiKeyboardDateField);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Details</Typography>
        </Grid>
        <Grid item container xs={12} md={8} spacing={3}>
          <Grid item xs={12} md={6}>
            <FormField field="firstName" />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField field="lastName" />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField field="mobilePhone" />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateField
              field="dateOfBirth"
              variant="inline"
              format="dd/MM/yyyy"
              TextFieldComponent={MyTextField}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">Address</Typography>
        </Grid>
        <Grid item container xs={12} md={8} spacing={3}>
          <Grid item xs={12} md={6}>
            <FormField field="address1" />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField field="address2" />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField field="postcode" />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField field="state" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
