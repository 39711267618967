import { BehaviorSubject } from "rxjs";
import {
  Product,
  CartClient,
  CartItem,
  ApiException,
  CartForJolse,
} from "../gensrc/client";
import { APIClients, ApiContext, UserAuth } from "./clients";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const Cart = {
  itemCount$: new BehaviorSubject<number>(0),
};

export type CartFuncs = {
  addItemToCart(product: ProductQuantity): Promise<any>;
  deleteItem(product: ProductQuantity): Promise<any>;
  getCartItems(): Promise<CartForJolse>;
  updateQuantity(product: ProductQuantity): Promise<any>;
};

export type ProductQuantity = {
  productId: string;
  quantity: number;
};

export function cartForUser(client: APIClients, user: UserAuth): CartFuncs {
  const cartClient = client.cartClient;

  return {
    addItemToCart(product) {
      return cartClient
        .addItemToCart({
          ...product,
        })
        .then((_) => Cart.itemCount$.next(Cart.itemCount$.value + 1));
    },
    deleteItem(product) {
      return cartClient.deleteCartItem({
        ...product,
      });
    },
    getCartItems() {
      return cartClient.getCartItems().then((ci) => {
        Cart.itemCount$.next(ci.cartItems!.length);
        return ci;
      });
    },
    updateQuantity(product) {
      return cartClient.updateCartItem({
        ...product,
      });
    },
  };
}
