import { useState, useEffect } from "react";
import { useAuthenticatedUser } from "./clients";
import {
  Container,
  Typography,
  makeStyles,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItem,
  List,
  Card,
  CardHeader,
  Grid,
  CardContent,
} from "@material-ui/core";
import React from "react";
import { OrderHistoryDTO, OrderHistoryLine } from "../gensrc/client";
import { imageLink } from "./utils";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { FullProgress } from "./fullprogress";

const useStyles = makeStyles((t) => ({
  main: { paddingTop: t.spacing(3) },
  orders: { marginTop: t.spacing(2) },
}));

export const ViewOrdersPath = "/orders";

export function ViewOrders() {
  const classes = useStyles();
  const [apiClients, user] = useAuthenticatedUser();
  const [orders, setOrders] = useState<OrderHistoryDTO[]>();
  useEffect(() => {
    apiClients.ordersClient.orderHistory().then(setOrders);
  }, []);

  function renderOrderItem(order: OrderHistoryLine) {
    const product = order.product!;
    return (
      <ListItem key={order.orderLineId}>
        <ListItemAvatar>
          <Avatar src={imageLink(product.imageId!)} />
        </ListItemAvatar>
        <ListItemText
          primary={`${order.quantity}x ${product.name}`}
          secondary={product.shortDescription}
        />
      </ListItem>
    );
  }

  function renderOrder(order: OrderHistoryDTO) {
    return (
      <Grid item xs={12} md={4} key={order.orderId}>
        <Card>
          <CardHeader
            title={`Order ${DateTime.fromISO(
              order.orderDate!
            ).toLocaleString()}`}
          ></CardHeader>
          <CardContent>
            <Typography>Ready: {order.shipped ? "Yes" : "No"}</Typography>
            <Typography>Invoiced: {order.invoiced ? "Yes" : "No"}</Typography>
            <Typography>Total: ${order.total.toFixed(2)}</Typography>
            <List>{order.orderLines!.map(renderOrderItem)}</List>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Container maxWidth="lg" className={classes.main}>
      <Typography variant="h4" align="center">
        Orders
      </Typography>
      {orders ? (
        orders.length > 0 ? (
          <Grid container spacing={2} className={classes.orders}>
            {orders.map(renderOrder)}
          </Grid>
        ) : (
          <Typography>
            You currently have no orders, why don't you do some{" "}
            <Link to={"/shop"}>shopping</Link> with us.
          </Typography>
        )
      ) : (
        <FullProgress />
      )}
    </Container>
  );
}
