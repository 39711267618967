import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ApiContext } from "./clients";
import { Container, Typography } from "@material-ui/core";
import { useGlobalApi } from "./global";

export const ConfirmEmailPrefix = "/confirm/";

export const ConfirmEmailPath = ConfirmEmailPrefix + ":userId/:code";

export function ConfirmEmail() {
  const { userId, code } = useParams<{ userId: string; code: string }>();
  const apiClient = useContext(ApiContext);
  const globalApi = useGlobalApi();
  useEffect(() => {
    apiClient.authClient
      .confirmEmail(userId, code)
      .then((c) => globalApi.loggedIn(c, false));
  }, []);
  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center">
        Thank you
      </Typography>
      <Typography>You email is now confirmed. TODO</Typography>
    </Container>
  );
}
