import React, { useState, useEffect, useRef } from "react";
import { useAuthenticatedUser } from "./clients";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import {
  CustomerPage,
  CustomerForm,
  formToCustomer,
  formFromCustomer,
  emptyCustomerForm,
} from "./customer";
import { useBackOrHome } from "./utils";
import { FullProgress } from "./fullprogress";
import { useGlobalApi } from "./global";
export const UpdateDetailsPath = "/editdetails";

export function UpdateDetails() {
  const [apiClient, user] = useAuthenticatedUser();
  const globalApi = useGlobalApi();
  const customerForm = useRef<CustomerForm>();

  const backOrHome = useBackOrHome();

  useEffect(() => {
    if (user.customerId) {
      apiClient.clubPackClient
        .getCustomer(user.customerId!)
        .then((customer) => {
          customerForm.current = formFromCustomer(customer);
          setFormId((i) => i + 1);
        });
    } else {
      customerForm.current = emptyCustomerForm;
      setFormId((i) => i + 1);
    }
  }, []);

  const [valid, setValid] = useState(true);
  const [formId, setFormId] = useState(0);

  function saveData() {
    if (customerForm.current) {
      const formVal = customerForm.current;
      const newCustomer = formToCustomer(formVal);
      const updated: Promise<any> = user.customerId
        ? apiClient.clubPackClient.updateCustomer({
            ...newCustomer,
            customerID: user.customerId,
          })
        : apiClient.clubPackClient
            .signUp({
              customer: newCustomer,
            })
            .then((c) => globalApi.onCustomerId(c.customerID));
      updated.then(backOrHome);
    }
  }
  return (
    <Container maxWidth="lg">
      {!customerForm.current ? (
        <FullProgress />
      ) : (
        <>
          <CustomerPage
            initialValue={customerForm.current}
            onStateChange={(s) => {
              setValid(s.valid);
              customerForm.current = s.value;
            }}
          />
          <Box my={2}>
            <Button
              color="primary"
              variant="contained"
              disabled={!valid}
              onClick={() => saveData()}
            >
              Save
            </Button>
            &nbsp;
            <Button color="secondary" variant="contained" onClick={backOrHome}>
              Cancel
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
}
