import { useContext, useState, useEffect, useMemo } from "react";
import { ApiContext } from "./clients";
import {
  Container,
  Card,
  CardContent,
  Typography,
  CardMedia,
  makeStyles,
  Grid,
  Button,
  CardHeader,
  Checkbox,
  Input,
  FormControlLabel,
  FormGroup,
  Paper,
  Tabs,
  Tab,
  Drawer,
  Hidden,
  IconButton,
  MuiThemeProvider,
} from "@material-ui/core";
import React from "react";
import { Product, ProductDTO } from "../gensrc/client";
import { grey } from "@material-ui/core/colors";
import useDebounce, { imageLink } from "./utils";
import { useLocation, useHistory } from "react-router-dom";
import { cartForUser, ProductQuantity, CartFuncs } from "./cart";
import { LoginPath } from "./login";
import { Quantity } from "./quantity";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import { ViewCartPath } from "./viewcart";
import InfiniteScroll from "react-infinite-scroller";
import { useGlobalApi } from "./global";
import Alert from "@material-ui/lab/Alert";
import { FullProgress } from "./fullprogress";
import clsx from "clsx";
import { storeFrontConfig } from "./storeconfig";

// const beerImg = require("../public/sample-beer.jpg");

const drawerWidth = 260;

const useStyles = makeStyles((t) => ({
  boxImage: { height: 320, backgroundSize: "auto 100%" },
  topContainer: { margin: t.spacing(2) },
  avatar: { backgroundColor: t.palette.primary.main },
  header: { height: "6em", color: t.palette.primary.contrastText },
  categoryList: { margin: t.spacing(2) },
  productList: { marginTop: t.spacing(4) },
  subCatFilter: { marginTop: t.spacing(1) },
  drawerPaper: { width: drawerWidth },
  search: {
    width: t.spacing(9),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchMain: {
    display: "flex",
    [t.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
    justifyContent: "space-between",
    background: t.palette.background.default,
    padding: t.spacing(1),
    position: "sticky",
    top: 0,
    zIndex: 100,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: t.spacing(1, 1, 1, 9),
  },
  mainContent: {
    display: "flex",
    width: "100%",
  },
  centerPanel: {
    maxWidth: t.breakpoints.values.lg,
    flexGrow: 1,
  },
  subCatMain: {
    width: drawerWidth,
    [t.breakpoints.down("xs")]: {
      display: "none",
    },
    position: "sticky",
    top: 0,
    alignSelf: "flex-start",
  },
  root: {
    display: "flex",
    fontFamily: t.typography.fontFamily,
    position: "relative",
    marginRight: t.spacing(2),
    marginLeft: t.spacing(1),
    borderRadius: t.shape.borderRadius,
    backgroundColor: "rgba(255, 255, 255, 0.10)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.20)",
    },
    "& $inputInput": {
      transition: t.transitions.create("width"),
      width: 120,
      "&:focus": {
        width: 170,
      },
    },
  },
  textOnBg: {
    color: t.palette.primary.contrastText,
  },
  subCategories: {
    margin: t.spacing(1),
  },
  sticky: {
    position: "sticky",
    top: 0,
  },
}));

type ProductCardProps = {
  product: ProductDTO;
  inCart: boolean;
  onAdd: (quantity: number) => void;
};

export function ProductCard({ product, onAdd, inCart }: ProductCardProps) {
  const [quantity, setQuantity] = useState(1);
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <CardHeader
          className={classes.header}
          title={
            <Typography variant="h5" color="textPrimary" align="center">
              {product.name}
            </Typography>
          }
        />
        {product && (
          <CardMedia
            className={classes.boxImage}
            image={imageLink(product.imageId!)}
            title={product.name}
          />
        )}
        <CardContent>
          <div
            dangerouslySetInnerHTML={{
              __html: product.description || "",
            }}
          />
          <Typography variant="subtitle1" color="textSecondary">
            {product.subCategory}
          </Typography>
          <Grid container alignItems="baseline" justify="space-between">
            <div>Price: ${(product.sellPrice * quantity).toFixed(2)}</div>
            {product.showRemainingUnits && (
              <div>{product.remainingUnits} left</div>
            )}
            <Quantity
              quantity={quantity}
              max={
                product.applyMaxPurchaseUnits
                  ? product.maxPurchaseUnits
                  : undefined
              }
              onChange={setQuantity}
            />
          </Grid>
          {!inCart ? (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => onAdd(quantity)}
            >
              Add to cart
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => {
                window.scrollTo({ top: 0 });
                history.push(ViewCartPath);
              }}
            >
              View cart
            </Button>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}

const PageLen = 9;

export function Products(props: { categories?: string[] }) {
  const apiClients = useContext(ApiContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<ProductQuantity>();
  const [rawQuery, setQuery] = useState("");
  const query = useDebounce(rawQuery, 500);
  const [products, setProducts] = useState<ProductDTO[]>();
  const [cartItems, setCartItems] = useState<string[]>();
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    props.categories ?? ["Beer"]
  );
  const [allCategories, setAllCategories] = useState<string[]>([]);
  const [subCategories, setSubCategories] = useState<string[]>([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>(
    []
  );
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const globalApi = useGlobalApi();

  function handleDrawerToggle() {
    setDrawerOpen((a) => !a);
  }

  useEffect(() => {
    loadMore(true);
  }, [selectedCategories, selectedSubCategories, query]);

  function loadMore(reset: boolean) {
    setHasMore(false);
    const actualOffset = reset ? 0 : offset;
    apiClients.productClient
      .listProducts(
        selectedCategories,
        selectedSubCategories,
        actualOffset,
        PageLen,
        query,
        undefined
      )
      .then((newp) => {
        setOffset(actualOffset + newp.length);
        setHasMore(newp.length === PageLen);
        setProducts((p) => (reset || !p ? newp : [...p, ...newp]));
      });
  }

  function getExistingCart(cart: CartFuncs) {
    cart
      .getCartItems()
      .then((ci) =>
        setCartItems(ci.cartItems!.map((i) => i.product!.productID))
      );
  }

  useEffect(() => {
    if (location.state && apiClients.authenticated) {
      history.replace({ ...location, state: undefined });
      const cart = cartForUser(apiClients, apiClients.authenticated);
      cart.addItemToCart(location.state).then(() => {
        globalApi.addSnack(<Alert severity="success">Added to cart</Alert>);
        return getExistingCart(cart);
      });
    } else if (apiClients.authenticated) {
      const cart = cartForUser(apiClients, apiClients.authenticated);
      getExistingCart(cart);
    }
    apiClients.productClient
      .categories(undefined)
      .then((c) => setAllCategories(c.filter((a) => a !== "Specials").sort()));
  }, []);

  useEffect(() => {
    apiClients.productClient
      .categories(selectedCategories[0])
      .then(setSubCategories);
  }, [selectedCategories]);

  function changeCategory(cat: string) {
    setSelectedSubCategories([]);
    setSelectedCategories([cat]);
  }

  function toggleSubCategory(subCat: string) {
    setSelectedSubCategories((c) =>
      c.indexOf(subCat) > -1 ? c.filter((ca) => ca !== subCat) : [...c, subCat]
    );
  }

  function addProduct(p: ProductDTO, quantity: number) {
    const prodQuan = { productId: p.productID, quantity };
    if (apiClients.authenticated) {
      const cart = cartForUser(apiClients, apiClients.authenticated);
      cart.addItemToCart(prodQuan);
      setCartItems((ci) => (ci ? [...ci, p.productID] : [p.productID]));
    } else {
      history.push(LoginPath, {
        ...location,
        state: prodQuan,
      });
    }
  }

  const subCategory = (
    <div className={classes.subCategories}>
      <Typography variant="h6" align="center">
        Filter by Category
      </Typography>
      <div className={classes.categoryList}>
        <FormGroup>
          {subCategories.map((name) => (
            <FormControlLabel
              key={name}
              value={name}
              control={
                <Checkbox
                  checked={selectedSubCategories.indexOf(name) > -1}
                  className={classes.textOnBg}
                  onChange={() => toggleSubCategory(name)}
                />
              }
              label={name}
            />
          ))}
        </FormGroup>
      </div>
    </div>
  );

  const productCards = useMemo(() => {
    return (
      products &&
      products.length > 0 && (
        <div className={classes.productList}>
          <InfiniteScroll loadMore={() => loadMore(false)} hasMore={hasMore}>
            <Grid container spacing={4}>
              {products.map((p) => (
                <ProductCard
                  key={p.productID}
                  product={p}
                  inCart={
                    cartItems ? cartItems.indexOf(p.productID) != -1 : false
                  }
                  onAdd={(q) => addProduct(p, q)}
                />
              ))}
            </Grid>
          </InfiniteScroll>
        </div>
      )
    );
  }, [products, cartItems]);

  function categoryFilter() {
    return (
      <Container maxWidth="sm" disableGutters>
        <Paper variant="outlined">
          <Tabs
            value={selectedCategories[0]}
            onChange={(c, v) => changeCategory(v)}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
          >
            {allCategories.map((c) => (
              <Tab key={c} label={c} value={c} />
            ))}
          </Tabs>
        </Paper>
      </Container>
    );
  }

  return (
    <div className={classes.mainContent}>
      <div className={classes.subCatMain}>{subCategory}</div>
      <div className={classes.centerPanel}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {subCategory}
          </Drawer>
        </Hidden>
        <div className={classes.searchMain}>
          <Hidden smUp>
            <IconButton onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.root}>
            <div className={classes.search}>
              <SearchIcon />
            </div>
            <Input
              disableUnderline
              type="search"
              placeholder="Search..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={(c) => setQuery(c.currentTarget.value)}
            />
          </div>
        </div>
        <div className={classes.topContainer}>
          {products ? (
            <>
              {!props.categories && categoryFilter()}
              {productCards}
            </>
          ) : (
            <FullProgress />
          )}
        </div>
      </div>
    </div>
  );
}
