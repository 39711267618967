import React from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import RemoveIcon from "@material-ui/icons/RemoveCircle";

const useStyles = makeStyles((t) => ({
  quantity: {
    display: "flex",
    alignItems: "center",
  },
}));

export type QuantityProps = {
  quantity: number;
  onChange: (q: number) => void;
  max?: number;
};
export function Quantity({ quantity, onChange, max }: QuantityProps) {
  const classes = useStyles();
  function changeQuantity(dir: number) {
    return () => {
      onChange(quantity + dir);
    };
  }
  return (
    <div className={classes.quantity}>
      Qty:
      <IconButton
        size="small"
        disabled={quantity <= 1}
        onClick={changeQuantity(-1)}
      >
        <RemoveIcon />
      </IconButton>
      {quantity}
      <IconButton
        size="small"
        disabled={max ? quantity >= max : false}
        onClick={changeQuantity(1)}
      >
        <AddIcon />
      </IconButton>
    </div>
  );
}
