import React, { useState, useContext, useEffect, RefAttributes } from "react";
import {
  Grid,
  Container,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { MUIFormControl, muiFieldRenderer } from "@react-typed-form/mui";
import {
  FormGroupState,
  ControlDefs,
  useFormGroup,
} from "@react-typed-form/core";
import { SignUpModelForJolse } from "../gensrc/client";
import { ApiContext } from "./clients";
import { useLocation } from "react-router-dom";
import { BaseUrl } from "./config";
import { badRequestToErrors } from "./utils";
import { ConfirmEmailPrefix } from "./confirm";
import { useGlobalApi } from "./global";
import Alert, { AlertProps } from "@material-ui/lab/Alert";

export const SignUpPath = "/signup";

type SignUpForm = Omit<SignUpModelForJolse, "confirmAccountUrl">;

const FormGroupSignUp: ControlDefs<SignUpForm, MUIFormControl> = {
  email: {
    label: "Email",
    autoComplete: "email",
    required: true,
    fullWidth: true,
    validator: (v) => (!v ? "Empty" : undefined),
  },
  password: {
    label: "Password",
    required: true,
    type: "password",
    fullWidth: true,
    autoComplete: "new-password",
    // validator: (v) =>
    //   v.length < 6
    //     ? "Password too short, must be 6 characters"
    //     : !PassRegex.test(v)
    //     ? "Must contain at least one digit"
    //     : undefined,
  },
  confirmPassword: {
    label: "Confirm Password",
    required: true,
    type: "password",
    fullWidth: true,
    autoComplete: "new-password",
    // validator: (v, ctx) =>
    //   v !== ctx.password ? "Password does not match" : undefined,
  },
  firstName: { label: "First Name", required: true, fullWidth: true },
  lastName: { label: "Last Name", required: true, fullWidth: true },
  dateOfBirth: { label: "Date of birth", required: true, fullWidth: true },
};

const useStyles = makeStyles((t) => ({
  fields: {
    margin: t.spacing(1),
  },
}));

export function SignUpPage() {
  const classes = useStyles();
  const apiClients = useContext(ApiContext);
  const globalApi = useGlobalApi();
  const location = useLocation();
  const [valid, setValid] = useState(false);
  const [signingUp, setSigningUp] = useState(false);
  const { state, FormField, updateState } = useFormGroup(
    FormGroupSignUp,
    {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      dateOfBirth: "",
    },
    muiFieldRenderer,
    (state) => setValid(state.valid)
  );

  globalApi.useHideNav();

  function createAccount() {
    setSigningUp(true);
    const formValue = state.current.value;
    apiClients.authClient
      .createUser({
        ...formValue,
        confirmAccountUrl: BaseUrl + ConfirmEmailPrefix,
      })
      .then((a) => {
        globalApi.loggedIn(a, false);
        globalApi.addSnack(<Alert severity="success">Account created</Alert>);
        globalApi.backOrHome(location);
      })
      .catch((e) => {
        const errors = badRequestToErrors<SignUpForm>(e);
        setSigningUp(false);
        updateState({ dirty: true, touched: true }, errors);
      });
  }

  function showForm() {
    return (
      <>
        <Typography variant="h4" align="center" className={classes.fields}>
          Create Account
        </Typography>
        <Grid container spacing={3} className={classes.fields}>
          <Grid item xs={10}>
            <FormField field="email" />
          </Grid>
          <Grid item xs={10}>
            <FormField field="password" />
          </Grid>
          <Grid item xs={10}>
            <FormField field="confirmPassword" />
          </Grid>
          <Grid item xs={10}>
            <FormField field="firstName" />
          </Grid>
          <Grid item xs={10}>
            <FormField field="lastName" />
          </Grid>
          <Grid item xs={10}>
            <FormField field="dateOfBirth" />
          </Grid>
          <Grid item xs={10}>
            <Button
              variant="contained"
              disabled={!valid || signingUp}
              color="primary"
              fullWidth
              onClick={() => createAccount()}
            >
              Sign Up
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  return <Container maxWidth="xs">{showForm()}</Container>;
}
