import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((t) => ({
  progress: {
    display: "flex",
    justifyContent: "center",
    marginTop: t.spacing(2),
  },
}));

export function FullProgress() {
  const classes = useStyles();
  return (
    <div className={classes.progress}>
      <CircularProgress color="secondary" />
    </div>
  );
}
