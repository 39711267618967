import { useState, useEffect, useMemo, useCallback } from "react";
import { useAuthenticatedUser } from "./clients";
import {
  Container,
  Typography,
  makeStyles,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItem,
  List,
  Button,
  CircularProgress,
  ListItemSecondaryAction,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Backdrop,
  Grid,
  Paper,
} from "@material-ui/core";
import React from "react";
import { CartItem, Customer, CartForJolse } from "../gensrc/client";
import { imageLink, ProgressButton } from "./utils";
import { ProductQuantity, cartForUser, Cart } from "./cart";
import { useStripe } from "@stripe/react-stripe-js";
import { useLocation, useHistory } from "react-router-dom";
import { BaseUrl } from "./config";
import DeleteIcon from "@material-ui/icons/Delete";
import { Quantity } from "./quantity";
import { storeFrontConfig } from "./storeconfig";
import { ViewOrdersPath } from "./vieworders";
import { googleMaps } from "./maps";
import { green, yellow, grey } from "@material-ui/core/colors";
import { FullProgress } from "./fullprogress";

export const ViewCartPath = "/viewcart";

const useStyles = makeStyles((t) => ({
  topContainer: { margin: t.spacing(2) },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: t.spacing(2),
  },
  button: {
    marginRight: t.spacing(1),
  },
  shipping: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  strike: {
    textDecoration: "line-through",
  },
  map: { height: 240, margin: t.spacing(2, 0) },
  pickupOnly: { marginTop: t.spacing(2) },
  price: {
    marginTop: t.spacing(1),
    color: t.palette.text.secondary,
  },
  cartTitle: {
    color: t.palette.text.primary,
  },
}));

type CartItemDisplayProps = {
  item: CartItem;
  onDelete: () => void;
  onChange: (quantity: number) => void;
};

function CartItemDisplay({ item, onDelete, onChange }: CartItemDisplayProps) {
  const product = item.product!;
  const quantity = item.quantity;
  const classes = useStyles();

  return (
    <ListItem key={product.productID} disableGutters>
      <ListItemAvatar>
        <Avatar src={imageLink(product.imageId!)} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography className={classes.cartTitle}>{product.name}</Typography>
        }
        secondary={
          <>
            <Quantity quantity={quantity} onChange={onChange} />
            <Typography className={classes.price}>
              Price: ${item.cartTotal.toFixed(2)}
            </Typography>
          </>
        }
        disableTypography
      />
      <ListItemSecondaryAction>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export function ViewCart() {
  const classes = useStyles();
  const [apiClients, user] = useAuthenticatedUser();
  const location = useLocation();
  const history = useHistory();

  const [products, setProducts] = useState<CartItem[]>();
  const [fullCart, setFullCart] = useState<CartForJolse>();
  const [customer, setCustomer] = useState<Customer>();
  const [pickup, setPickup] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const cart = cartForUser(apiClients, user);

  useEffect(() => {
    cart.getCartItems().then((fc) => {
      setProducts(fc.cartItems!);
      setFullCart(fc);
      if (!fc.postage) {
        setPickup(true);
      }
    });
    apiClients.clubPackClient.getCustomer(user.customerId!).then(setCustomer);
  }, []);

  const shippingAvailable = storeFrontConfig.canShip && fullCart?.postage;
  const successUrl = BaseUrl + ViewOrdersPath;
  const cancelUrl = BaseUrl + history.createHref(location);

  function pay() {
    setLoading(true);
    apiClients.cartClient
      .convertCartToSale({
        successUrl,
        cancelUrl,
        pickup,
      })
      .then((sessionId) => {
        stripe?.redirectToCheckout({ sessionId });
      });
  }

  const mapRef = useCallback((div: HTMLDivElement) => {
    if (div) {
      googleMaps().then(
        (maps) =>
          new maps.Map(div, {
            center: {
              lat: storeFrontConfig.latitude,
              lng: storeFrontConfig.longitude,
            },
            disableDefaultUI: true,
            zoomControl: true,
            zoom: 18,
          })
      );
    }
  }, []);

  function subscription() {
    setLoading(true);
    apiClients.cartClient
      .convertCartToSubscription({
        successUrl,
        cancelUrl,
        pickup,
      })
      .then((sessionId) => {
        stripe?.redirectToCheckout({ sessionId });
      });
  }

  function toProductQuantity(item: CartItem): ProductQuantity {
    return {
      productId: item.product!.productID!,
      quantity: item.quantity,
    };
  }

  function deleteItem(item: CartItem) {
    cart.deleteItem(toProductQuantity(item)).then(() => {
      Cart.itemCount$.next(Cart.itemCount$.value - 1);
      setProducts((p) => (p ? p.filter((i) => i !== item) : []));
    });
  }

  function updateItem(item: CartItem, index: number) {
    if (item.quantity === 0) {
      return deleteItem(item);
    }
    cart.updateQuantity(toProductQuantity(item)).then((ci) => {
      setProducts((p) => {
        if (p) {
          const newCopy = [...p];
          newCopy[index] = ci;
          return newCopy;
        } else [];
      });
    });
  }

  const itemTotal = products
    ? products.reduce((total, item) => total + item.cartTotal, 0)
    : 0;
  const postage = fullCart?.postage?.amount;

  function pickupOrDelivery() {
    return shippingAvailable ? (
      <RadioGroup
        name="pickup"
        value={pickup}
        onChange={(c, v) => setPickup(v === "true")}
      >
        <FormControlLabel
          value={true}
          control={<Radio />}
          label="Pickup from:"
        />
        <Paper>
          <div ref={mapRef} className={classes.map}></div>
        </Paper>

        <div className={classes.shipping}>
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Ship to:"
          />
          {customer && (
            <div>
              <Typography>
                {customer.postalAddress?.addressLine1},
                {customer.postalAddress?.addressLine3}
              </Typography>
              <Typography>
                {customer.postalAddress?.postcode},
                {customer.postalAddress?.state}
              </Typography>
            </div>
          )}
        </div>
      </RadioGroup>
    ) : (
      <div className={classes.pickupOnly}>
        <Typography variant="subtitle1">Pick up from:</Typography>
        <Typography variant="subtitle2">
          {storeFrontConfig.address1}, {storeFrontConfig.address3},{" "}
          {storeFrontConfig.postcode}
        </Typography>
        <Paper>
          <div ref={mapRef} className={classes.map}></div>
        </Paper>
      </div>
    );
  }

  function totals() {
    const totalRows = [
      ["Sub-total:", itemTotal, false, shippingAvailable],
      ["Postage:", postage, pickup, shippingAvailable],
      ["Total:", itemTotal + (pickup ? 0 : postage ?? 0), false, true],
    ] as [string, number | undefined, boolean, boolean][];
    return totalRows.map(([text, value, strike, show]) => (
      <Grid key={text} container justify="flex-end">
        <Grid item xs={3}>
          {show && (
            <Typography
              variant="button"
              className={strike ? classes.strike : undefined}
            >
              {text}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          {show && value !== undefined && (
            <Typography
              variant="button"
              align="right"
              className={strike ? classes.strike : undefined}
            >
              ${value.toFixed(2)}
            </Typography>
          )}
        </Grid>
      </Grid>
    ));
  }

  const noProducts = !products || products.length == 0;

  function showNoProducts() {
    return (
      <Typography align="center">Your cart is currently empty.</Typography>
    );
  }

  return (
    <Container maxWidth="sm" disableGutters>
      {!products || !fullCart ? (
        <FullProgress />
      ) : (
        <div className={classes.topContainer}>
          <Typography variant="h4" color="textPrimary" align="center">
            My cart
          </Typography>
          {noProducts ? (
            showNoProducts()
          ) : (
            <>
              <List dense disablePadding>
                {products.map((i, index) => (
                  <CartItemDisplay
                    key={i.id}
                    item={i}
                    onDelete={() => deleteItem(i)}
                    onChange={(q) => updateItem({ ...i, quantity: q }, index)}
                  />
                ))}
              </List>
              {totals()}
              <ProgressButton loading={loading}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  onClick={pay}
                >
                  Checkout
                </Button>
              </ProgressButton>
              {pickupOrDelivery()}
              <div className={classes.buttons}>
                {storeFrontConfig.subscriptions && (
                  <ProgressButton loading={loading}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      onClick={subscription}
                    >
                      Subscribe
                    </Button>
                  </ProgressButton>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </Container>
  );
}
