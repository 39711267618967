declare global {
  interface Window {
    initMap(): void;
  }
}

var mapsPromise: Promise<typeof google.maps>;

export function googleMaps(): Promise<typeof google.maps> {
  if (!mapsPromise) {
    mapsPromise = new Promise((resolve, reject) => {
      window.initMap = () => resolve(google.maps);
      var scriptTag = window.document.createElement("script");
      scriptTag.async = true;
      scriptTag.defer = true;
      scriptTag.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAGeXtPTQ6fmWx1nbXFySfn4F6D1WDXAr0&callback=initMap";
      document.body.append(scriptTag);
    });
  }
  return mapsPromise;
}
