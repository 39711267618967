import { useContext, useState, useEffect, Fragment } from "react";
import { ApiContext, APIClients, UserAuth } from "./clients";
import {
  Container,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
  makeStyles,
  Grid,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItem,
  List,
  CardActions,
  Button,
  CardHeader,
  Box,
} from "@material-ui/core";
import React from "react";
import { ClubPack, ClubPackLine, ClubPackBox } from "../gensrc/client";
import { Link, useHistory, useLocation } from "react-router-dom";
import { imageLink } from "./utils";
import * as H from "history";
import { useStripe } from "@stripe/react-stripe-js";
import { LoginPath } from "./login";
import { BaseUrl } from "./config";
import { ViewOrdersPath } from "./vieworders";
import { FullProgress } from "./fullprogress";

const useStyles = makeStyles((t) => ({
  boxImage: { height: 240 },
  title: {
    display: "flex",
    alignItems: "center",
  },
  topContainer: { margin: t.spacing(2) },
}));

export function useSubscribeToPack(): (packId: string) => void {
  const client = useContext(ApiContext);
  const location = useLocation();
  const history = useHistory();
  const stripe = useStripe();
  return (packId) => {
    if (client.authenticated) {
      client.clubPackClient
        .addSubscriptionToOrder({
          packId,
          customerId: client.authenticated.customerId!,
          successUrl: BaseUrl + ViewOrdersPath,
          cancelUrl: BaseUrl + history.createHref(location),
        })
        .then((res) => {
          stripe!.redirectToCheckout({
            sessionId: res.id!,
          });
        });
    } else {
      history.replace(LoginPath, { ...location, state: { packId } });
    }
  };
}

export function ShowPacks() {
  const apiClient = useContext(ApiContext);
  const history = useHistory();
  const location = useLocation<{ packId: string }>();
  const [clubPacks, setClubPacks] = useState<ClubPack[]>();
  const classes = useStyles();
  const subscribe = useSubscribeToPack();

  useEffect(() => {
    if (location.state && apiClient.authenticated) {
      history.replace({ ...location, state: undefined });
      subscribe(location.state.packId);
    } else {
      apiClient.clubPackClient
        .getClubPacks("freedomis", apiClient.authenticated?.customerId)
        .then(setClubPacks);
    }
  }, []);

  function renderItem(line: ClubPackLine) {
    const product = line.product!;
    return (
      <ListItem key={line.id}>
        <ListItemAvatar>
          <Avatar src={imageLink(product.imageId!)} />
        </ListItemAvatar>
        <ListItemText
          primary={`${line.quantity}x ${product.name}`}
          secondary={product.shortDescription}
        />
      </ListItem>
    );
  }

  function viewBox(packId: string, boxId: string) {
    history.push("/pack/" + packId + "/box/" + boxId);
  }

  const renderBox = (pack: ClubPack) => (b: ClubPackBox) => {
    const packId = pack.id;
    return (
      <Grid item xs={12} md={4} key={b.id}>
        <Card>
          <CardHeader
            title={b.active ? `Upcoming box - ${b.name}` : b.name}
          ></CardHeader>
          <CardActionArea onClick={() => viewBox(packId, b.id)}>
            {b.imageId && (
              <CardMedia
                className={classes.boxImage}
                image={imageLink(b.imageId)}
                title={b.name}
              />
            )}
            <CardContent>
              <div
                dangerouslySetInnerHTML={{
                  __html: b.description || "",
                }}
              />
              <List>{b.items!.map(renderItem)}</List>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button color="primary" onClick={() => viewBox(packId, b.id)}>
              View box
            </Button>
            {!pack.customerIsSubscribed && (
              <Button color="secondary" onClick={() => subscribe(packId)}>
                Subscribe
              </Button>
            )}
          </CardActions>
        </Card>
      </Grid>
    );
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.topContainer}>
        {!clubPacks ? (
          <FullProgress />
        ) : (
          clubPacks.map((cp) => (
            <Fragment key={cp.id}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Typography variant="h3" color="textPrimary">
                  {cp.name}
                </Typography>
                {!cp.customerIsSubscribed && (
                  <Box m={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => subscribe(cp.id)}
                    >
                      ${cp.price.toFixed(2)} Subscribe
                    </Button>
                  </Box>
                )}
              </Grid>
              <Box m={2}>
                <Typography color="textSecondary">{cp.description}</Typography>
              </Box>
              <Grid container spacing={4}>
                {cp.boxes!.map(renderBox(cp))}
              </Grid>
            </Fragment>
          ))
        )}
      </div>
    </Container>
  );
}
